<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-icon size="50" color="grey darken-1">
            mdi-alert-circle
          </v-icon>
        </v-card-text>
        <v-card-text class="heading text-center">
          Are you sure you want to delete this item?
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text color="red darken-2" @click="onConfirm">Yes</v-btn>
          <v-btn text color="green darken-2" @click="dialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "DeleteDialog",
    props: {
      onDelete: { type: Function },
    },
    data() {
      return {
        dialog: false,
        value: null
      }
    },
    methods: {
      openDialog(value) {
        this.dialog = true;
        this.value = value;
      },
      onConfirm() {
        this.dialog = false;
        this.onDelete(this.value);
      }
    }
  }
</script>

<style scoped>

</style>
