<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-icon size="50" color="primary darken-1">
            mdi-check-circle
          </v-icon>
        </v-card-text>
        <v-card-text class="heading text-center">
          {{ message }}
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text color="green darken-2" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "SuccessDialog",
    props: {
      message: String
    },
    data() {
      return {
        dialog: false,
      }
    },
    methods: {
      openDialog() {
        this.dialog = true;
      },
    }
  }
</script>

<style scoped>

</style>
