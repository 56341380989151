import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: {
        forAuth: true,
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          alias: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: {
            featureName: 'dashboard'
          }
        },
        {
          name: 'DurationList',
          path: '/durations',
          component: () => import('@/views/durations/List'),
          meta: {
            featureName: 'durations'
          }
        },
        {
          path: 'users',
          component: () => import('@/views/users/Index'),
          meta: {
            featureName: 'users'
          },
          children: [
            {
              name: 'Users',
              path: '',
              component: () => import('@/views/users/List'),
              meta: {
                featureName: 'users'
              },
            },
            {
              name: 'Update user',
              path: 'update/:id',
              component: () => import('@/views/users/Update'),
              meta: {
                forAdmin: true,
                featureName: 'users'
              },
            },
            {
              name: 'Add user',
              path: 'create',
              component: () => import('@/views/users/Add'),
              meta: {
                forAdmin: true,
                featureName: 'users'
              },
            },
          ],
        },
        {
          name: 'Notifications',
          path: 'notifications',
          component: () => import('@/views/notifications/Index'),
          children: [
            {
              name: 'Notifications',
              path: '',
              component: () => import('@/views/notifications/List'),
              meta: {
                featureName: 'notifications'
              }
            },
            {
              name: 'Notification Change Requests',
              path: 'change-requests/:notification_id',
              component: () => import('@/views/notifications/ChangeRequestsList'),
              meta: {
                featureName: 'notifications'
              }
            },
            {
              name: 'Create Change Request',
              path: 'create-change-request/:notification_id',
              component: () => import('@/views/notifications/Create'),
              meta: {
                featureName: 'notifications'
              }
            }
          ]
        },
        {
          path: 'sms',
          component: () => import('@/views/sms/Index'),
          children: [
            {
              name: 'SMS',
              path: '',
              component: () => import('@/views/sms/List'),
              meta: {
                featureName: 'sms'
              }
            },
            {
              name: 'SMS Change Requests',
              path: 'change-requests/:duration',
              component: () => import('@/views/sms/ChangeRequestsList'),
              meta: {
                featureName: 'sms'
              }
            },
            {
              name: 'Create Change Request',
              path: 'create-change-request/:duration',
              component: () => import('@/views/sms/Create'),
              meta: {
                featureName: 'sms'
              }
            },
          ]
        },
        {
          path: 'subscribers',
          component: () => import('@/views/subscriber/Index'),
          children: [
            {
              name: 'Subscriber List',
              path: '',
              component: () => import('@/views/subscriber/List'),
              meta: {
                featureName: 'subscribers'
              }
            },
            {
              name: 'Create Subscriber',
              path: 'create',
              component: () => import('@/views/subscriber/Create'),
              meta: {
                featureName: 'subscribers'
              }
            },
          ],
        },
        {
          path: 'branches',
          component: () => import('@/views/branches/Index'),
          children: [
            {
              name: 'Branches List',
              path: '',
              component: () => import('@/views/branches/List'),
              meta: {
                featureName: 'branches'
              }
            },
            {
              name: 'Create Branch',
              path: 'create',
              component: () => import('@/views/branches/Create'),
              meta: {
                forAdmin: true,
                featureName: 'branches'
              },
            },
            {
              name:"Edit Branch",
              path: 'edit/:id',
              component: () => import('@/views/branches/Edit'),
              meta: {
                forAdmin: true,
                featureName: 'branches'
              },
            },
          ],
        },
        {
          path: 'coupons',
          component: () => import('@/views/coupons/Index'),
          children: [
            {
              name: 'Coupons List',
              path: '',
              component: () => import('@/views/coupons/List'),
              meta: {
                featureName: 'coupons'
              }
            },
            {
              name: 'Create Coupon',
              path: 'create',
              component: () => import('@/views/coupons/Create'),
              meta: {
                featureName: 'coupons'
              }
            },
          ],
        },
        {
          path: 'sheets',
          component: () => import('@/views/sheets/Index'),
          children: [
            {
              name: 'SheetList',
              path: '',
              component: () => import('@/views/sheets/List'),
              meta: {
                featureName: 'sheets'
              }
            },
            {
              name: 'CreateSheet',
              path: 'create',
              component: () => import('@/views/sheets/Create'),
              meta: {
                featureName: 'sheets'
              }
            },
            {
              name: 'FailedRows',
              path: ':id/failed-rows',
              component: () => import('@/views/sheets/FailedRows'),
              meta: {
                featureName: 'sheets'
              }
            }
          ],
        },
        {
          path: '/reports',
          component: () => import('@/views/reports/Index'),
          children: [
            {
              name: 'Subscriptions',
              path: 'subscriptions',
              component: () => import('@/views/reports/Subscriptions'),
              meta: {
                forAdmin: true,
                featureName: 'subscription_reports'
              },
            },
            {
              name: 'Consultations',
              path: 'consultations',
              component: () => import('@/views/reports/Consultations'),
              meta: {
                forAdmin: true,
                featureName: 'consultation_reports'
              },
            },

          ],
          meta: {
            forAdmin: true
          },
        },
        {
          name: 'Optional Fields',
          path: 'optional-fields',
          component: () => import('@/views/optionalFields/List'),
          meta: {
            featureName: 'optional_fields'
          }
        },
        {
          path: 'consultations',
          component: () => import('@/views/consultations/Index'),
          children: [
            {
              name: 'Consultations List',
              path: '',
              component: () => import('@/views/consultations/List'),
              meta: {
                featureName: 'consultations'
              }
            },
            {
              name: 'CreateConsultation',
              path: 'create',
              component: () => import('@/views/consultations/Create'),
              meta: {
                featureName: 'create_consultation'
              }
            },
            {
              name: 'ShowConsultation',
              path: ':id',
              component: () => import('@/views/consultations/Show'),
              meta: {
                featureName: 'consultations'
              }
            },
          ],
        },
        {
          path: 'consultation-referrals',
          component: () => import('@/views/consultations/Index'),
          children: [
              {
                  name: 'ShowReferral',
                  path: ':id',
                  component: () => import('@/views/consultations/ShowReferral'),
                  meta: {
                      featureName: 'consultation_referrals'
                  }
              },
              {
                name: 'ConsultationReferrals',
                path: '',
                component: () => import('@/views/consultations/Referrals'),
                meta: {
                  featureName: 'consultation_referrals'
                },
              }
          ]
        },
        {
          path: 'consultation-drug-delivery',
          component: () => import('@/views/consultation-drugs/Index'),
          children: [
            {
              name: 'ConsultationDrugDelivery',
              path: '',
              component: () => import('@/views/consultation-drugs/List'),
              meta: {
                featureName: 'dispense'
              }
            },
            {
              name: 'DispenseDrug',
              path: ':id/dispense',
              component: () => import('@/views/consultation-drugs/Dispense'),
              meta: {
                featureName: 'dispense'
              }
            },
          ],
        },
      ],
    },
    {
      path: '/auth',
      component: () => import('@/views/auth/Login'),
      children: [
        {
          path: 'login',
          meta: {
            forGuests: true
          }
        }
      ]
    },
  ],
})
