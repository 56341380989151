<template>
  <v-alert
    v-if="Object.keys(validationErrors).length"
    dense
    outlined
    type="error">
    <ul>
      <li v-for="(errors, field) in validationErrors">
        {{ field }}
        <ul>
          <li v-for="error in errors">
            {{ error }}
          </li>
        </ul>
      </li>
    </ul>
  </v-alert>
</template>

<script>
  export default {
    name: "VValidationErrors",
    props: {
      validationErrors: Object
    }
  }
</script>

<style scoped>

</style>
