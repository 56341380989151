<template>
  <thead>
  <tr>
    <th class="primary--text" v-for="(param, index) in params" v-if="params[index].type !== 'pagination'">
      <v-text-field
        v-if="params[index].type === 'text_field'"
        v-model="params[index].value"
        :label="$t(`fields.${params[index].name}`)"
        class="purple-input mt-3"
        small
        v-on:keyup.enter="onInputChange()"
        rounded
        filled
        dense
        outlined
      />
      <v-select
        @input="onInputChange"
        class="mt-3"
        v-if="params[index].type === 'select'"
        :items="params[index].items"
        :label="$t(`fields.${params[index].name}`)"
        v-model="params[index].value"
        item-value="value"
        item-text="text"
        clearable
        rounded
        filled
        dense
        outlined
      />
    </th>
  </tr>
  </thead>
</template>

<script>
  export default {
    name: "FilterList",
    props: {
      filters: {
        type: Array
      },
      onChange: {
        type: Function
      }
    },
    data () {
      return {
        params: this.filters,
        query: {}
      }
    },
    mounted() {
      this.$root.$on('refreshParams', (newParams) => {
        this.params = newParams
      });
    },
    methods: {
      onInputChange () {
        let _this = this;
        this.params.forEach(function (value) {
          _this.query[value['name']] = value['value'];
        });
        this.$router.push({ query: this.query })
      },
    }
  }
</script>

<style scoped>

</style>
